.clubs-status {
  position: absolute;
  left: 50%;
  top: 5rem;
  z-index: 3;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;

  &.show {
    opacity: 1 !important;
  }
}

#clubs-status-information {
  width: 100%;
  padding: 0px 1rem; // do RWD
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -3rem);
  text-align: center;
  pointer-events: none;

  &.show {
    transform: translate(-50%, 0px);
  }

  p {
    background: white;
    padding: 0.55rem 1rem;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
    font-size: 1em;
    text-align: center;
    display: inline-block;
  }
}

#clubs-status-loading {
  width: 100px;
  height: 40px;
  background: url('/assets/loading_horizontal.gif') no-repeat center;
  background-size: cover;
  pointer-events: none;
  display: block;
  opacity: 0;
  transform: translateX(-50%);
}