.field-wrapper {
  position: relative;

  .error-label {
    position: absolute;
    font-size: 0.7em;
    color: $errorColor;
    top: -1px;
    left: 0px;
    transform: translateY(-100%);
  }
}