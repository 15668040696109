.errors {
  li {
    color: $errorColor;
    position: relative;
    padding-left: 1rem;

    &:before {
      width: 5px;
      height: 5px;
      background: $errorColor;
      border-radius: 100%;
      content: '';
      display: block;
      position: absolute;
      left: 3px;
      top: 8px;
    }
  }
}