@import "./fieldWrapper/fieldWrapper";
@import "./errors/errors";
@import "./imageUploader/imageUploader";
@import "./loadingWrapper/loadingWrapper";
@import "./pageOverlay/pageOverlay";

.standard-button {
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  padding: 0px 1rem;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
  opacity: 0.7;

  transition: opacity 0.3s;

  &.small-button {
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 15px;
  }

  &.button-green {
    background: $correctColor;
    color: white;
  }

  &.button-blue {
    background: $standardColor;
    color: white;
  }

  &:hover {
    opacity: 1;
  }
}

.input-styles {
  height: 3rem;
  padding: 0px 1rem;
  border: 1px solid $borderLightColor;
  border-radius: $borderRadius;
  width: 100%;
  display: block;
  background: white;

  box-sizing: border-box;

  &.opened {
    border-radius: $borderRadius $borderRadius 0px 0px;
  }

  &.error {
    border-color: $errorColor;
  }
}

.styled-input {
  @extend .input-styles;
}

.styled-textarea {
  @extend .input-styles;

  height: 8rem;
  padding: 1rem;
  resize: none;
}

.section-header,
.relations-header {
  font-size: 1.25em;
  color: $textColor;
  font-weight: normal;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }

  &.error {
    color: $errorColor;
  }
}

.relations-header {
  position: relative;
  z-index: 0;

  .text {
    background: $backgroundColor;
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 2;
    padding-right: 1rem;

    .clubs-number {
      padding-left: 0.25rem;
      font-weight: bold;
    }
  }

  &:after {
    width: 100%;
    height: 6px;
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
  }

  &.no-relation-header {
    &:after {
      background: $minorTextColor;
    }
  }

  &.friendships-header {
    &:after {
      background: $correctColor;
    }
  }

  &.agreements-header {
    &:after {
      background: $standardColor;
    }
  }

  &.positives-header {
    &:after {
      background: $additionalColor2;
    }
  }

  &.enemies-header {
    &:after {
      background: $additionalColor3;
    }
  }

  &.satellites-header {
    &:after {
      background: $additionalColor1;
    }
  }

  &.satelliteof-header {
    &:after {
      background: $additionalColor1;
    }
  }

  &.upcoming-match-header {
    font-weight: bold;

    &:after {
      background: black;
    }
  }
}

.has-scrollbar {
  &:hover {
    .track-vertical {
      .thumb-vertical {
        opacity: 1;
      }
    }
  }

  .track-vertical {
    .thumb-vertical {
      opacity: 0;
    }
  }
}

.track-vertical {
  top: 2px;
  bottom: 2px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.111);

  transition: opacity 0.3s;

  .thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(123, 154, 255, 0.418);

    transition: opacity 0.3s;
  }
}

.clipped-hover {
  position: absolute;
  content: '';
  display: block;
  width: calc(100% - 6rem);
  height: 100%;
  top: 0px;
  right: 0px;
  transform: translateX(100%);
  background-color: $lightHoverBackground;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 2rem 100%);

  transition: opacity 0.3s, transform 0.3s;
}

.copyright-email {
  display: inline-block;
  margin-left: 0.25rem;
  
  a {
    color: $standardColor;

    &:hover {
      text-decoration: underline;
    }
  }
}