$backgroundColor: #FFFCFF; // #FBFEF9
$textColor: #191923;
$minorTextColor: lighten($textColor, 30%);
$standardColor: #0E79B2; // Agreement
$errorColor: #ED254E;
$correctColor: #248232; // Friendship

$additionalColor1: #420039; // Satellite
$additionalColor2: #80DED9; // Positive relation
$additionalColor3: #b80c00; // Enemy

$lightHoverBackground: #f0f0f0;

$borderRadius: 5px;
$borderLightColor: hsl(0,0%,80%);

$fullSizeMargin: 1rem;