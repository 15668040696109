#clubs-toggle-show {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: $backgroundColor;
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 0px;
  transform: translate(-5rem, -50%);
  opacity: 0;

  transition: transform 0.3s, opacity 0.3s;

  &.show {
    transform: translate(-2rem, -50%);
    opacity: 1;
  }

  svg {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 800px) {
  #clubs-toggle-show {
    width: 3rem;
    height: 3rem;
    top: 3.5rem;
    left: 50%;

    transform: translate(-50%, -3rem);

    &.show {
      transform: translate(-50%, 0px);
    }

    svg {
      font-size: 1.75em;
    }
  }
}