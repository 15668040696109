.page-over-map-wrapper {
  position: absolute;
  top: 0px;
  z-index: 6;
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
  padding: 2rem 0px;

  box-sizing: border-box;
}

.page-over-map {
  z-index: 100;
  background: $backgroundColor;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 2rem 0px;
  box-sizing: border-box;
  position: relative;

  .track-vertical {
    right: 12px;
  }

  .page-over-close {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 2.5rem;
    height: 2.5rem;
    background: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px -1px 6px 1px rgba(0, 0, 0, 0.25);
  }
  
  .inner {
    padding: 0px 2rem;
    overflow: hidden;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1700px) {
  .page-over-map-wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 1400px) {
  .page-over-map-wrapper {
    width: 100%;
    left: 0px;
    transform: none;
    padding: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .page-over-map-wrapper {
    padding: 2rem 1rem;
  }

  .page-over-map {
    .inner {
      padding: 0px 1rem;
    }

   .track-vertical {
     right: 5px;
   }
  }
}